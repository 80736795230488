<section class="perfilDesk" style=" margin-top: 100px;">
  <div class="perfilCont">
    <div class="taitl-cont">
      <img src="/assets/img/micuenta.webp" width="670px" height="auto">
    </div>
    <div class="opts-cont">
      <div class="opt" (click)="infoDesk()">
        <i class="fa-solid fa-circle-user"></i>
        <span class="opt-name">Información</span>
      </div>
      <div class="opt" routerLink="/direcciones">
        <i class="fa-solid fa-location-dot"></i>
        <span class="opt-name">Direcciones</span>
      </div>
      <div class="opt" (click)="histDesk()">
        <i class="fa-solid fa-calendar-days"></i>
        <span class="opt-name">Historial de pedidos</span>
      </div>
      <div class="opt" (click)="puntDesk()">
        <i class="fa-solid fa-medal"></i>
        <span class="opt-name">Lidepuntos</span>
      </div>
      <div class="opt" routerLink="/mi-perfil">
        <!-- <i class="fa-solid fa-users"></i>
        <span class="opt-name">Mi Perfil</span> -->
        <img src="/assets/img/miperfil.webp" width="670px" height="auto">
      </div>
      <div class="opt" [routerLink]="['/mesa-regalos-landing']">
        <img src="/assets/img/mesita.webp" width="670px" height="auto">

      </div>



      <!-- <div class="taitl-cont2">
        <div class="inputContainer">


          <input type="text" class="input" placeholder="a">
          <label for="" class="label">Canjea tu codigo aquí</label>
          <button ><img src="/assets/img/37.jpg"></button>


        </div>
 
      </div> -->



    </div>

    <div class="infoDesk" #info_Desk>
      <i class="fa-solid fa-circle-xmark closen" (click)="infoDesk()"></i>
      <div class="compra">
        <div class="cont-opc">
          <ion-label position="floating">Nombre</ion-label>
          <ion-input type="text" name="nombre" [(ngModel)]="nombre"></ion-input>
        </div>
        <div class="cont-opc">
          <ion-label position="floating">Apellidos</ion-label>
          <ion-input type="text" name="apellido" [(ngModel)]="apellido"></ion-input>
        </div>
        <div class="cont-opc">
          <ion-label position="floating">RFC</ion-label>
          <ion-input type="text" name="rfc" [(ngModel)]="rfc" class="custom-placeholder"
            placeholder="Opcional, solo si se quiere factura"></ion-input>
        </div>
        <div class="cont-opc">
          <ion-label position="floating">Correo Electrónico</ion-label>
          <ion-input type="email" name="correo" [(ngModel)]="correo" disabled="true"></ion-input>
        </div>
        <ion-button style="margin-top:15px;" expand="block" (click)="actualizar()">Actualizar</ion-button>
        <ion-button style="margin-top:15px; position: relative;" expand="block" color="danger"
          (click)="borrame()">Borrar</ion-button>
      </div>
    </div>

    <div class="dirDesk" #dir_Desk>
      <i class="fa-solid fa-circle-xmark closen" (click)="dirDesk()"></i>
      <div class="fix">
        <div class="compra" *ngFor="let item of direcciones">
          <div class="info">
            <div class="text">
              <p>{{item.alias}}</p>
              <p>{{item.address1}}</p>
              <p>{{nombreEstado}} | {{item.city}} | {{item.colony}}</p>
            </div>
            <div class="icons">
              <!-- <ion-icon name="search-circle-outline"></ion-icon>
              <ion-icon name="refresh-circle-outline"></ion-icon> -->
            </div>
          </div>
          <div class="ent">{{item.date_add}}</div>
          <div class="ent"> <button (click)="agregarDir()">Editar</button></div>

        </div>

        <div class="direccion2" #agrega_Dir>

          <div class="titled">
            <p>Ingresa la información de la nueva dirección</p>
            <i class="fa-solid fa-circle-chevron-left closin" (click)="agregaDir()"></i>
          </div>
          <div class="contina">
            <input class="form-control inps" type="text" [(ngModel)]="alias" name="alias" placeholder="Alias Dirección">
            <input class="form-control inps" type="text" name="telefono" [(ngModel)]="telefono" placeholder="Telefono">
            <input class="form-control inps" type="text" name="zipcode" [(ngModel)]="zipcode"
              (change)="accionesDireccion(zipcode, 'zipcode')" placeholder="Código Postal">
            <input class="form-control inps" type="text" name="address1" [(ngModel)]="address1" placeholder="Dirección">
            <input class="inps" type="text" name="address2" [(ngModel)]="address2"
              placeholder="Dirección Complementaria">
            <select class="selict" name="id_country">
              <option value="1">Selecciona tu País</option>
              <option value="145" selected>México</option>
            </select>
            <select class="selict" name="id_state" [(ngModel)]="estado" (change)="accionesDireccion(estado, 'state')">
              <option value="" selected>Provincia o Estado</option>
              <option value="57">Aguascalientes</option>
              <option value="58">Baja California</option>
              <option value="59">Baja California Sur</option>
              <option value="60">Campeche</option>
              <option value="61">Chiapas</option>
              <option value="62">Chihuahua</option>
              <option value="63">Coahuila</option>
              <option value="64">Colima</option>
              <option value="65">Distrito Federal</option>
              <option value="66">Durango</option>
              <option value="67">Guanajuato</option>
              <option value="68">Guerrero</option>
              <option value="69">Hidalgo</option>
              <option value="70">Jalisco</option>
              <option value="71">Estado de México</option>
              <option value="72">Michoacán</option>
              <option value="73">Morelos</option>
              <option value="74">Nayarit</option>
              <option value="75">Nuevo León</option>
              <option value="76">Oaxaca</option>
              <option value="77">Puebla</option>
              <option value="78">Querétaro</option>
              <option value="79">Quintana Roo</option>
              <option value="80">San Luis Potosí</option>
              <option value="81">Sinaloa</option>
              <option value="82">Sonora</option>
              <option value="83">Tabasco</option>
              <option value="84">Tamaulipas</option>
              <option value="85">Tlaxcala</option>
              <option value="86">Veracruz</option>
              <option value="87">Yucatán</option>
              <option value="88">Zacatecas</option>
            </select>

            <select class="selict" name="city" [(ngModel)]="city" (change)="accionesDireccion(city, 'city')">
              <option value="" selected>Ciudad</option>
            </select>

            <select class="selict" name="colony" [(ngModel)]="colony" (change)="accionesDireccion(colony,'colony')">
              <option value="" selected>Colonia</option>
            </select>

            <button (click)="agregarDir()">Agregar</button>
          </div>
        </div>
      </div>
      <button (click)="agregaDir()">Agregar Dirección</button>
    </div>

    <div class="histDesk" #hist_Desk>
      <i class="fa-solid fa-circle-xmark closen" (click)="histDesk()"></i>
      <h2 class="titulo">Tus Ventas</h2>
      <div class="contenHist">
        <div class="compra" *ngFor="let item of pedidos">
          <div class="info">
            <div class="text">
              <p><strong>Orden de Venta:</strong> {{item.ov}}</p>
              <p><strong>Fecha:</strong> {{item.fechita}}</p>
              <p><strong>Método de Pago:</strong> {{item.metodo_pago}}</p>
              <p><strong>Estado:</strong> {{item.nameEstado}}</p>
            </div>
            <div class="icons">
              <button [routerLink]="['/venta',item.ov]">
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="puntDesk" #punt_Desk>
      <i class="fa-solid fa-circle-xmark closen" (click)="puntDesk()"></i>
      <div class="puntCont">
        <span class="title">Tus Lidepuntos actuales:</span>
        <div class="conten">
          <div class="ent">{{totalpuntos}}</div>
        </div>
        <div class="fin">
          <span class="txt">¿Quieres obtener más puntos?</span>
          <a class="linka" routerLink="/lidepuntos">Haz clic aquí</a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="desaparece-desk" style="position: relative;">

  <div class="perfil">
    <div class="btn" (click)="informacione()">
      <div class="shadow"></div>
      <ion-icon name="person-circle-outline"></ion-icon>
      <p>Información</p>
    </div>
    <div class="btn" routerLink="/direcciones">
      <div class="shadow"></div>
      <ion-icon name="location-outline"></ion-icon>
      <p>Direcciones</p>
    </div>
    <div class="btn" (click)="historiale()">
      <div class="shadow"></div>
      <ion-icon name="calendar-outline"></ion-icon>
      <p>Historial de pedidos</p>
    </div>
    <div class="btn" (click)="Lidepunte()">
      <div class="shadow"></div>
      <ion-icon name="calendar-outline"></ion-icon>
      <p>Lidepuntos</p>
    </div>
    <div class="btn" routerLink="/mi-perfil">
      <div class="shadow"></div>
      <ion-icon name="calendar-outline"></ion-icon>
      <p>Mi Perfil</p>
    </div>
    <div class="btn" [routerLink]="['/mesa-regalos-landing']">
      <div class="shadow"></div>
      <ion-icon name="calendar-outline"></ion-icon>
      <p>Mesa de Regalos</p>
    </div>

    <!-- <div class="btn">
      <div class="shadow"></div>
      <ion-icon name="receipt-outline"></ion-icon>
      <p>Recibos de crédito</p>
    </div>
    <div class="btn">
      <div class="shadow"></div>
      <ion-icon name="pricetag-outline"></ion-icon>
      <p>Mis Vales</p>
    </div>
    <div class="btn">
      <div class="shadow"></div>
      <ion-icon name="book-outline"></ion-icon>
      <p>Mis datos</p>
    </div>
    <div class="btn">
      <div class="shadow"></div>
      <ion-icon name="heart-outline"></ion-icon>
      <p>My loyalty points</p>
    </div> -->
  </div>

  <div class="historial" #historial>
    <div class="volver" (click)=" historiale()">
      <ion-icon name="return-up-back-outline"></ion-icon>
      <p>Volver</p>
    </div>
    <div class="titled">
      <p>Estos son los pedidos que han realizado desde que se creó tu cuenta:</p>
    </div>
    <div class="compra" *ngFor="let item of pedidos">
      <div class="info">
        <div class="text">
          <p>{{item.ov}}</p>
          <p>{{item.fechita}}</p>
          <p>{{item.metodo_pago}}</p>
        </div>
      </div>
      <div class="ent"> <button [routerLink]="['/venta',item.ov]">Ver venta</button></div>
    </div>
  </div>



  <div class="informacion" #informacion>
    <div class="volver" (click)="informacione()">
      <ion-icon name="return-up-back-outline"></ion-icon>
      <p>Volver</p>
    </div>
    <div class="titled">
      <p>Esta es la información de tu cuenta:</p>
    </div>

    <div class="compra">
      <div style="margin-top:25px ; ">
        <ion-item>
          <ion-label position="floating">Nombre</ion-label>
          <ion-input type="text" name="nombre" [(ngModel)]="nombre"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Apellidos</ion-label>
          <ion-input type="text" name="apellido" [(ngModel)]="apellido"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">RFC</ion-label>
          <ion-input type="text" name="rfc" [(ngModel)]="rfc" class="custom-placeholder"
            placeholder="Opcional, solo si se quiere factura"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Correo Electrónico</ion-label>
          <ion-input type="email" name="correo" [(ngModel)]="correo" disabled="true"></ion-input>
        </ion-item>
        <ion-button style="margin-top:15px ;" expand="block" (click)="actualizar()">Actualizar</ion-button>
        <ion-button style="margin-top:15px ;" expand="block" color="danger" (click)="borrame()">Borrar</ion-button>
      </div>
    </div>
  </div>

  <div class="direccion" #direccion>
    <div class="volver" (click)="direccione()">
      <ion-icon name="return-up-back-outline"></ion-icon>
      <p>Volver</p>
    </div>
    <div class="titled">
      <p>Estos son las direcciones de tu cuenta:</p>
    </div>
    <div class="compra" *ngFor="let item of direcciones">
      <div class="info">
        <div class="text">
          <p>{{item.alias}}</p>
          <p>{{item.address1}}</p>
          <p>{{nombreEstado}} | {{item.city}} | {{item.colony}}</p>
        </div>
        <div class="icons">
          <!-- <ion-icon name="search-circle-outline"></ion-icon>
          <ion-icon name="refresh-circle-outline"></ion-icon> -->
        </div>
      </div>
      <div class="ent">{{item.date_add}}</div>
    </div>
    <ion-button expand="block" (click)="direccione2()">Agregar Dirección</ion-button>
  </div>

  <div class="direccion2" #direccion2>
    <div class="volver" (click)=" quita()">
      <ion-icon name="return-up-back-outline"></ion-icon>
      <p>Volver</p>
    </div>
    <div class="titled">
      <p>Ingresa la información de la nueva dirección</p>
    </div>
    <div class="compra">
      <input class="form-control inps" type="text" [(ngModel)]="alias" name="alias" placeholder="Alias Dirección">
      <input class="form-control inps" type="text" name="telefono" [(ngModel)]="telefono" placeholder="Telefono">
      <input class="form-control inps" type="text" name="zipcode" [(ngModel)]="zipcode"
        (change)="accionesDireccion(zipcode, 'zipcode')" placeholder="Código Postal">
      <input class="form-control inps" type="text" name="address1" [(ngModel)]="address1" placeholder="Dirección">
      <input class="inps" type="text" name="address2" [(ngModel)]="address2" placeholder="Dirección Complementaria">
      <select class="selict" name="id_country">
        <option value="1">Selecciona tu País</option>

        <option value="145" selected>México</option>
      </select>
      <select class="selict" name="id_state" [(ngModel)]="estado" (change)="accionesDireccion(estado, 'state')">
        <option value="" selected>Provincia o Estado</option>
        <option value="57">Aguascalientes</option>
        <option value="58">Baja California</option>
        <option value="59">Baja California Sur</option>
        <option value="60">Campeche</option>
        <option value="61">Chiapas</option>
        <option value="62">Chihuahua</option>
        <option value="63">Coahuila</option>
        <option value="64">Colima</option>
        <option value="65">Distrito Federal</option>
        <option value="66">Durango</option>
        <option value="67">Guanajuato</option>
        <option value="68">Guerrero</option>
        <option value="69">Hidalgo</option>
        <option value="70">Jalisco</option>
        <option value="71">Estado de México</option>
        <option value="72">Michoacán</option>
        <option value="73">Morelos</option>
        <option value="74">Nayarit</option>
        <option value="75">Nuevo León</option>
        <option value="76">Oaxaca</option>
        <option value="77">Puebla</option>
        <option value="78">Querétaro</option>
        <option value="79">Quintana Roo</option>
        <option value="80">San Luis Potosí</option>
        <option value="81">Sinaloa</option>
        <option value="82">Sonora</option>
        <option value="83">Tabasco</option>
        <option value="84">Tamaulipas</option>
        <option value="85">Tlaxcala</option>
        <option value="86">Veracruz</option>
        <option value="87">Yucatán</option>
        <option value="88">Zacatecas</option>

      </select>

      <select class="selict" name="city" [(ngModel)]="city" (change)="accionesDireccion(city, 'city')">
        <option value="" selected>Ciudad</option>

      </select>

      <select class="selict" name="colony" [(ngModel)]="colony" (change)="accionesDireccion(colony,'colony')">
        <option value="" selected>Colonia</option>
      </select>
      <!-- <div style="margin-top:25px ; ">
        <ion-item>
          <ion-label position="floating" >Alias</ion-label>
          <ion-input type="text" name="alias" [(ngModel)]="alias"></ion-input>
        </ion-item>
    
        <ion-item>
          <ion-label position="floating" >Nombre</ion-label>
          <ion-input  type="text"name="nombre" [(ngModel)]="nombreDir"></ion-input>
        </ion-item>
    
        <ion-item>
          <ion-label position="floating" >Apellidos</ion-label>
          <ion-input  type="text" name="apellido" [(ngModel)]="apellidoDir" ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating" >Empresa</ion-label>
          <ion-input  type="text" name="empresa" [(ngModel)]="empresa" ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating" >Telefono</ion-label>
          <ion-input  type="text" name="telefono" [(ngModel)]="telefono" ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating" >Código Postal</ion-label>
          <ion-input  type="text" name="cp" [(ngModel)]="cp" ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating" >Dirección</ion-label>
          <ion-input  type="text" name="address1" [(ngModel)]="address1" ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating" >Dirección Complementaria</ion-label>
          <ion-input  type="text" name="address2" [(ngModel)]="address2" placeholder="Opcional"></ion-input>
        </ion-item> -->


      <ion-button style="margin-top:15px ;" expand="block" (click)="agregarDir()">Agregar</ion-button>

    </div>

  </div>

  <div class="lidepuntos" #lidepuntos>
    <div class="volver" (click)=" Lidepunte()">
      <ion-icon name="return-up-back-outline"></ion-icon>
      <p>Volver</p>
    </div>
    <div class="titled">
      <p>Estos son los Lidepuntos actuales en tu cuenta:</p>
    </div>
    <div class="compra">
      <div class="info">
        <div class="text">

        </div>
        <div class="icons">

        </div>
      </div>
      <div class="ent">{{totalpuntos}}</div>
    </div>
    <div class="titled">
      <p>¿Deseas saber como obtener mas puntos?</p>
      <ion-button style="margin-top:15px ;" expand="block" routerLink="/lidepuntos">Click Aquí</ion-button>
    </div>
  </div>

</div>

<!--
<ion-content [fullscreen]="true">
  <div *ngIf="txtBuscador != ''">
    <p class="line-filter">{{numeroResultados}} productos encontrados</p>
    
    <div class="search-container">
      <ion-item
        button
        (click)="openModal(producto.item.id_product)"
        *ngFor="let producto of resultadoBusqueda"
      >
        <ion-thumbnail slot="start">
          <img src="https://new.lideart.com.mx/{{producto.item.image_link}}" />
        </ion-thumbnail>
        <ion-label>
          <h3>{{producto.item.nombre}}</h3>
          <p>
            <span style="font-weight: lighter">
              SKU: {{producto.item.sku[0]}}
            </span>
          </p>
          <b>${{producto.item.precio | number}}</b>
        </ion-label>
        <ion-icon name="eye" style="color: #70c2d1" slot="end"></ion-icon>
      </ion-item>
    </div>
  </div>
  <div class="container">
    <ion-avatar>
    <img src="/assets/img/lideart_logo.png">
    </ion-avatar>
    <div class="content">
      <div class="info-container">
        <p class="name">Luis Valladolid</p>
        <p class="mail">sistemas18@avanceytec.com.mx</p>
      </div>
    </div>
  </div>
</ion-content>
-->