//@import url("../../../theme/variables.scss");


body {
  position: static !important;
}


ion-button {
  --box-shadow: transparent !important;
}

button {
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

ion-searchbar {
  --icon-color: #1ca3c9;
  width: 95%;
  margin-left: 10px;
}

.search-container {
  width: 90vw;
  right: 5vw;
  height: auto;
  max-height: 50vh;
  overflow-y: scroll;
  position: absolute;
  z-index: 99;
}

.line-filter {
  text-align: center;
  line-height: 20px;
  background: #70c2d1;
  padding: 0;
  margin: 0;
}

.header-md::after {
  display: none;
}

.container {
  height: 100%;
  background-color: var(--ion-color-primary);
  display: grid;
  justify-items: center;
}

.content {
  position: absolute;
  bottom: 0;
  background: var(--ion-color-light);
  width: 100%;
  height: 75%;
  border-radius: 4em 4em 0 0;
  box-shadow: 0px -14px 12px 6px rgb(0 0 0 / 20%);
}

.name {
  font-weight: bold;
}

.mail {
  font-weight: 100;
  margin-top: 1em;
}
.info-container {
  background-color: white;
  width: 88%;
  margin: 7em 5%;
  height: auto;
  border-radius: 1em;
  border: 1px solid var(--ion-color-medium);
  p {
    color: black;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
  }
}

ion-avatar {
  position: absolute;
  width: 10em;
  height: 10em;
  margin: 0 30vw;
  bottom: 65vh;
  z-index: 10;
  border: 10px solid var(--ion-color-light);
  box-shadow: 0px -14px 12px 6px rgb(0 0 0 / 20%);
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}
.header-translucent-ios ion-toolbar {
  --opacity: 1;
}





.perfil{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #fff;
  opacity: 0.8;

  .btn{
    width: 70%;
    height: 3rem;
    background: #128ab6;
    color: #fff;
    border-radius: 5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: .8rem;
    margin: 1.5rem 0rem;
    position: relative;

    ion-icon{
      font-size: 1.8rem;
    }

    p{
      font-size: 1.1rem;
      font-weight: lighter;
      margin-left: 1rem;
    }

    .shadow{
      width: 80%;
      height: .6rem;
      background: rgba(0, 0, 0, 0.152);
      position: absolute;
      bottom: -.6rem;
      left: 10%;
      backdrop-filter: blur(1px);
      border-radius: 0rem 0rem .4rem .4rem;
      box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
    }
  }
}

.historial{
  width: 100%;
  height: 100%;
  background: rgb(237, 237, 237);
  position: fixed;
  top: 3.5rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
  opacity: 0;
  z-index: -1;
  transition: all 2s;

  .volver{
    background: #1ed5cc;
    width: 6rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: .5rem;
    top: 8rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: .5rem;

    ion-icon{
      color: #fff;
      font-size: 1.5rem;
      margin-right: .4rem;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #fff;
      font-size: 1rem;
    }
  }

  .titled{
    width: 100%;
    height: 5rem;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: .5rem;

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: rgb(107, 107, 107);
      font-size: 1rem;
    }
  }

  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;

    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }

      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        ion-icon{
          font-size: 2.2rem;
        }
      }
    }

    .ent{
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
}

.inps{
  width: 100%;
  height: 2.5rem;
  background: none;
  text-indent: 1rem;
  border-radius: 5rem;
  border: 1px solid #33a1c3;
  margin-bottom: .5rem;
}

.inps:focus{
  outline: none;
}

.selict{
  width: 100%;
  height: 2.5rem;
  background: none;
  padding: 0rem 1rem;
  border-radius: .5rem;
  border: 1px solid #33a1c3;
  margin-bottom: .5rem;
}

.selict:focus{
  outline: none;
}

.informacion{
  width: 100%;
  height: 100%;
  background: rgb(237, 237, 237);
  position: fixed;
  top: 3.5rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
  opacity: 0;
  z-index: -1;
  transition: all 2s;

  .volver{
    background: #1ed5cc;
    width: 6rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: .5rem;
    top: 8rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: .5rem;

    ion-icon{
      color: #fff;
      font-size: 1.5rem;
      margin-right: .4rem;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #fff;
      font-size: 1rem;
    }
  }

  .titled{
    width: 100%;
    height: 5rem;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: .5rem;

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: rgb(107, 107, 107);
      font-size: 1rem;
    }
  }

  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;

    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }

      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        ion-icon{
          font-size: 2.2rem;
        }
      }
    }

    .ent{
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
}

.direccion{
  width: 100%;
  height: 100%;
  background: rgb(237, 237, 237);
  position: fixed;
  top: 3.5rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
  opacity: 0;
  z-index: -1;
  transition: all 2s;

  .volver{
    background: #1ed5cc;
    width: 6rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: .5rem;
    top: 8rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: .5rem;

    ion-icon{
      color: #fff;
      font-size: 1.5rem;
      margin-right: .4rem;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #fff;
      font-size: 1rem;
    }
  }

  .titled{
    width: 100%;
    height: 5rem;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: .5rem;

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: rgb(107, 107, 107);
      font-size: 1rem;
    }
  }

  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;

    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }

      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        ion-icon{
          font-size: 2.2rem;
        }
      }
    }

    .ent{
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
}

.lidepuntos{
  width: 100%;
  height: 100%;
  background: rgb(237, 237, 237);
  position: fixed;
  top: 3.5rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
  opacity: 0;
  z-index: -1;
  transition: all 2s;

  .volver{
    background: #1ed5cc;
    width: 6rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: .5rem;
    top: 8rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: .5rem;

    ion-icon{
      color: #fff;
      font-size: 1.5rem;
      margin-right: .4rem;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #fff;
      font-size: 1rem;
    }
  }

  .titled{
    width: 100%;
    height: 5rem;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: .5rem;

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: rgb(107, 107, 107);
      font-size: 1rem;
    }
  }

  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;

    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }

      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        ion-icon{
          font-size: 2.2rem;
        }
      }
    }

    .ent {
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
}

.direccion2{
  width: 100%;
  height: 100%;
  background: rgb(237, 237, 237);
  position: fixed;
  top: 3.5rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
  opacity: 0;
  z-index: -1;
  transition: all 2s;

  .volver{
    background: #1ed5cc;
    width: 6rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: .5rem;
    top: 8rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: .5rem;

    ion-icon{
      color: #fff;
      font-size: 1.5rem;
      margin-right: .4rem;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: #fff;
      font-size: 1rem;
    }
  }

  .titled{
    width: 100%;
    height: 5rem;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: .5rem;

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
      color: rgb(107, 107, 107);
      font-size: 1rem;
    }
  }

  .compra{
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;

    .info{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
          margin-block-start: 0em;
          margin-block-end: 0em;
          font-size: 1rem;
          color: rgb(107, 107, 107);
        }
      }

      .icons{
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;

        ion-icon{
          font-size: 2.2rem;
        }
      }
    }

    .ent{
      background: #1ed5cc;
      width: 12rem;
      height: 2.5rem;
      border-radius: 5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }
}


@media screen and (orientation: landscape) {
  ion-avatar {
    display: none;
  }
  .info-container {
    margin: 3em 5%;
  }
}

ion-content{
  display: block;
}

.perfilDesk{
  width: 100%;
  height: auto;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;

  .perfilCont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 0em;
    gap: 2em;
    position: relative;

    .taitl-cont{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .taitl{
        color: #00a3c7;
        font-size: 1.7em;
      }
    }

    .taitl-cont2{
      width: 85%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #ffb6bb;

      .taitl2{
        color: #00a3c7;
        font-size: 1.7em;
      }

      .inputContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 90%;
        padding:1em 0em ;
      

        input{
          background-color:#fff ;
          width: 100%;
          height: 2em;
        }

        button{
          position:absolute;
          right: 0;
          z-index: 1;
          height: 2em;
         img{
          width: 100%;
          height: 100%;
         }
        }
      }
      
      .input {
        height: 100%;
        width: 85%;
        border: 1px solid #979797;
        border-radius: 7px;
        font-size: 16px;
        padding: 0 20px;
        outline: none;
        background: none;
        z-index: 1;
        color: #000;
      }

      .textarea {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        border: 1px solid #000000;
        border-radius: 7px;
        font-size: 16px;
        padding: 0 20px;
        outline: none;
        background: none;
        z-index: 1;
      }
    
      .textarea:focus + .label {
        top: -7px;
        left: 3px;
        z-index: 10;
        font-size: 14px;
        font-weight: 600;
        color: rgb(0 155 186);
      }
    
      .textarea:focus {
        border: 2px solid rgb(0 155 186);
      }
    
      .textarea:not(:placeholder-shown)+ .label {
        top: -7px;
        left: 3px;
        z-index: 10;
        font-size: 14px;
        font-weight: 600;
      }
      
      /* Hide the placeholder texts (a) */
      
      ::placeholder {
        color: transparent;
      }
    
      .label {
        position: absolute;
        top: 15px;
        left: 15px;
        padding: 0 4px;
        background-color: #ffb6bb;
        color: #000000;
        font-size: 16px;
        transition: 0.5s;
        z-index: 0;
      }
    }

    .opts-cont{
      width: 50em;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2em;
      //border: 1px solid red;
      
      .opt{
        width: 20em;
        height: 10em;
        border-radius: 1em;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px 0px;
        transition: .3s all ease-in-out;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        i{
          color: #8b8b8b;
          font-size: 4em;
          transition: .3s all ease-in-out;
        }

        .opt-name{
          color: #8b8b8b;
          font-size: 1.2em;
          transition: .3s all ease-in-out;
        }
      }

      .opt:nth-child(1):hover{
        box-shadow: rgba(0, 163, 197, 0.5) 0px 1px 12px 1px;

        i{
          color: rgba(0, 163, 197, 0.8);
        }

        .opt-name{
          color: rgba(0, 163, 197, 0.8);
        }
      }

      .opt:nth-child(2):hover{
        box-shadow: rgba(0, 163, 197, 0.5) 0px 1px 12px 1px;

        i{
          color: rgba(0, 163, 197, 0.8);
        }

        .opt-name{
          color: rgba(0, 163, 197, 0.8);
        }
      }

      .opt:nth-child(3):hover{
        box-shadow: rgba(0, 163, 197, 0.5) 0px 1px 12px 1px;

        i{
          color: rgba(0, 163, 197, 0.8);
        }

        .opt-name{
          color: rgba(0, 163, 197, 0.8);
        }
      }

      .opt:nth-child(4):hover{
        box-shadow: rgba(0, 163, 197, 0.5) 0px 1px 12px 1px;

        i{
          color: rgba(0, 163, 197, 0.8);
        }

        .opt-name{
          color: rgba(0, 163, 197, 0.8);
        }
      }
    }

    .infoDesk {
      /*width: 100%;
      height: 100%;*/
      width: 0%;
      height: 0%;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      padding: 2em 0em;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s all ease-in-out;
      overflow: hidden;

      .closen{
        position: absolute;
        top: 1em;
        right: 1em;
        color: #00a3c7;
        font-size: 2em;
        transition: .3s all ease-in-out;
        opacity: 1;
        cursor: pointer;
      }

      .closen:hover{
        opacity: .6;
      }

      .compra{
        width: 30em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;

        .cont-opc{
          padding: 0.4em 0.7em;
          background-color: rgba(255, 255, 255, 0);
          border: 2px solid #00a3c7;
          border-radius: 0.7em 0em;

          ion-label{
            font-size: .7em;
            color: #00a3c7;
          }

          ion-input{
            --padding-top: 5px;
            --padding-bottom: 2px;
            border-bottom: 1px solid #ffffff6b;
            transition: .5s all ease-in-out;
            color: #00a3c7;
          }
        }
      }
    }

    .dirDesk{
      /*width: 100%;
      height: 100%;*/
      width: 0%;
      height: 0%;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      padding: 3em 0em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: .4s all ease-in-out;
      overflow: hidden;
      gap: 2em;

      .closen{
        position: absolute;
        top: 1em;
        right: 1em;
        color: #1135c0;
        font-size: 2em;
        transition: .3s all ease-in-out;
        opacity: 1;
        cursor: pointer;
      }

      .closen:hover{
        opacity: .6;
      }

      .fix{
        width: 30em;
        height: 20em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        align-items: center;
        background: #fff;
        box-shadow:  41px 41px 82px #f0f0f0,
             -41px -41px 82px #f0f0f0;
        border-radius: 1em;
        padding: 1em;
        overflow-y: scroll;
        position: relative;

        .compra{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: .7em;
          border-bottom: 1px solid #b3b3b3;
          border-bottom-style: dashed;
          padding: 0.7em 1em;
  
          .info{
            width: 100%;
            height: auto;

            .text{
              width: 100%;
              height: auto;
              
              p{
                font-size: .9em;
                margin-block-start: 0em;
                margin-block-end: 0em;
                color: #00aaff;
                animation: 1s parpcolor1 ease-in-out infinite;
              }
            }
          }

          .ent{
            font-size: .7em;
            color: #00aaff;
            animation: 1s parpcolor1 ease-in-out infinite;
          }

          @keyframes parpcolor1{
            0%,100%{
              color: #00aaffad;
            }

            50%{
              color: #00aaff;
            }
          }
        }

        .compra:last-child{
          border-bottom: none;
        }

        .direccion2{
          width: 0%;
          height: 0%;
          background: #1234bd;
          position: absolute;
          top: 0;
          left: 0;
          overflow-y: scroll;
          transition: .2s all ease-in-out;
          opacity: 1;
          z-index: 1;
          padding-bottom: 0;

          .titled{
            width: 100%;
            height: 4em;
            background: #1234bd;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0rem 2rem;
            box-shadow: none;
            margin-bottom: none;

            p{
              margin-block-start: 0em;
              margin-block-end: 0em;
              color: #00aaff;
              font-size: .9em;
            }

            .closin{
              position: absolute;
              left: 1em;
              color: #00aaff;
              cursor: pointer;
              font-size: 1.2em;
            }
          }

          .contina{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: .7em;
            padding: 0.7em 1em;

            .inps{
              width: 100%;
              height: 2.4em;
              background: none;
              text-indent: 1rem;
              border-radius: 5rem;
              color: #00aaff;
              border: 1px solid #00aaff;
              margin-bottom: none;
            }

            .inps::placeholder{
              color: #00aaff;
            }

            .selict{
              width: 100%;
              height: 2.4rem;
              background: none;
              padding: 0rem 1rem;
              border-radius: 2em;
              border: 1px solid #00aaff;
              margin-bottom: none;
              color: #00aaff;
            }
          }
        }
      }

      button{
        background-color: #1135c0!important;
        border: 1px solid #00aaff;
        color: #00aaff;
        padding: 0.7em 2em;
        border-radius: 2em;
      }
    }

    .histDesk{
      /*width: 100%;
      height: 100%;*/
      width: 0%;
      height: 0%;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      padding: 3em 0em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.4s all ease-in-out;
      overflow: hidden;
      gap: 1.5em;

      .closen {
        position: absolute;
        top: 1em;
        right: 1em;
        color: #1ca3c9;
        font-size: 2.5em;
        transition: 0.3s all ease-in-out;
        opacity: 1;
        cursor: pointer;
      }

      .closen:hover {
        opacity: 0.6;
      }

      .titulo {
        font-size: 1.8em;
        font-weight: bold;
        color: #1ca3c9; /* Color principal */
        text-align: center;
        margin-bottom: 1em;
      }

      .contenHist{
        width: 90%;
        max-width: 40em;
        height: 70%;
        border: 2px solid #1ca3c9;
        border-radius: 1em;
        background-color: transparent;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: scroll;

        .compra {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          height: auto;
          gap: 1em;
          padding: 1em;
          border-bottom: 1px dashed #52d3ff;

          .info{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .text {
              width: 80%;
              display: flex;
              flex-direction: column;
              gap: 0.5em;

              p {
                margin: 0;
                font-size: 1em;
                color: #1ca3c9;
                font-weight: 400;
              }

              p strong {
                color: #52d3ff; /* Resalta los títulos con el color secundario */
              }
            }

            .icons {
              button {
                width: 3.5em;
                height: 3.5em;
                border-radius: 50%;
                background: linear-gradient(135deg, #1ca3c9, #52d3ff);
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                  color: #fff;
                  font-size: 1.5em;
                  transition: 0.5s all ease-in-out;
                }
              }

              button:hover {
                i {
                  color: #1ca3c9;
                }
              }
            }
          }

          .ent{
            font-size: .9em;
            color: #fff;
          }
        }

        .compra:last-child{
          border-bottom: none;
        }
      }

      .contenHist::-webkit-scrollbar {
        width: 0.5em;
        background-color: transparent;
      }
      
      .contenHist::-webkit-scrollbar-thumb {
        background-color: #1ca3c9;
        //border-radius: 1vh;
        width: 0.5em;
      }
    }

    .puntDesk {
      /*width: 100%;
      height: 100%;*/
      width: 0%;
      height: 0%;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      padding: 3em 0em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.4s all ease-in-out;
      overflow: hidden;
      gap: 2em;

      .closen {
        position: absolute;
        top: 1em;
        right: 1em;
        color: #1ca3c9;
        font-size: 2em;
        transition: 0.3s all ease-in-out;
        opacity: 1;
        cursor: pointer;
      }

      .closen:hover {
        opacity: 0.6;
      }

      .puntCont {
        width: 25em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        padding: 1.5em;
        background: linear-gradient(135deg, #1ca3c9, #52d3ff);
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 1.5em;

        .title {
          font-weight: bold;
          font-size: 1.2em;
          color: #fff;
          text-align: center;
        }

        .conten {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          .ent {
            border: 2px solid #ffffff;
            font-size: 1.5em;
            font-weight: bold;
            color: #fff;
            padding: 0.8em 2.5em;
            border-radius: 3em;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }

        .fin {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: auto;
          gap: 0.5em;

          .txt {
            font-weight: 400;
            font-size: 0.9em;
            color: #fff;
            text-align: center;
          }

          .linka {
            text-decoration: none;
            font-weight: bold;
            font-size: 1em;
            transition: 0.3s all ease-in-out;
            color: #fff;
            background-color: #1ca3c9;
            padding: 0.5em 1.5em;
            border-radius: 2em;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          }

          .linka:hover{
            background-color: #52d3ff;
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media(min-width: 992px){
  .perfilDesk{
    display: flex;
  }

  ion-content{
    display: none;
  }
}


.perfilDesk::-webkit-scrollbar {
  width: .35em;
  background-color: transparent;
  height: .7em;
}

.perfilDesk::-webkit-scrollbar-thumb {
  background-color: #00a2c779;
  //border-radius: 1vh;
  width: 0.25em;
}